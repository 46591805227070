$font: Roboto;
$font-path: "/assets/vw/fonts/";
$format: "ttf";
@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Light." + $format);
  font-weight: 300;
}

@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Regular." + $format);
  font-weight: normal;
}

@font-face {
  font-family: $font;
  src: url($font-path + $font + "-Bold." + $format);
  font-weight: 700;
}
